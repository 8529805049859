import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './history-of-orders.css';

function HistoryOfOrders({ startPeriod, endPeriod }) {
  const { botId } = useParams();
  const token = localStorage.getItem('token');
  const apiUrl = process.env.REACT_APP_API_URL;
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${apiUrl}/orders/bot/${botId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;

        const filteredOrders = data
          .filter(order => order.side === 'BUY' && order.status === 'FILLED')
          .map(orderBought => {
            const orderSell = data.find(
              order =>
                order.side === 'SELL' &&
                (order.status === 'FILLED' || order.status === 'CANCELED') &&
                order.type === 'LIMIT_MAKER' &&
                order.symbol === orderBought.symbol &&
                order.quantity === orderBought.quantity
            );

            const orderStop = data.find(
              order =>
                order.side === 'SELL' &&
                (order.status === 'FILLED' || order.status === 'CANCELED') &&
                order.type === 'STOP_LOSS_LIMIT' &&
                order.symbol === orderBought.symbol &&
                order.quantity === orderBought.quantity
            );

            let orderSold = null;
            let profit = 0;
            let status = 'neutral';

            if (orderSell && orderSell.status === 'FILLED') {
              orderSold = orderSell;
              profit = (orderSold.price * orderSold.quantity) - (orderBought.price * orderBought.quantity);
            } else if (orderStop && orderStop.status === 'FILLED') {
              orderSold = orderStop;
              profit = (orderSold.price * orderSold.quantity) - (orderBought.price * orderBought.quantity);
            }

            if (profit > 0) {
              status = 'profit';
            } else if (profit < 0) {
              status = 'loss';
            }

            if (orderSold) {
              const updatedAt = new Date(orderSold.updatedAt);
              return {
                id: orderBought.id,
                status: status,
                symbol: orderBought.symbol,
                profit: profit.toFixed(3),
                amount: orderBought.amount,
                purchased: orderBought.price,
                sold: orderSold.price,
                rsi: orderBought.rsi,
                updatedAt,
                time: updatedAt.toLocaleString('en-GB', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                }),
              };
            }

            return null;
          })
          .filter(order => order !== null);

        filteredOrders.sort((a, b) => a.updatedAt - b.updatedAt);

        const finalOrders = filteredOrders.filter(order => {
          if (startPeriod && endPeriod) {
            const orderTime = order.updatedAt.getTime();
            return orderTime >= startPeriod && orderTime <= endPeriod;
          }
          return true;
        });

        setOrders(finalOrders);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError(error.message || 'Something went wrong');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [botId, token, apiUrl, startPeriod, endPeriod]);

  if (isLoading) {
    return <div className="loading">Loading history of orders...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="history-of-orders-container">
      {orders.length === 0 ? (
        <div className="no-orders">No completed orders found.</div>
      ) : (
        [...orders].reverse().map(order => (
          <div key={order.id} className="order">
            <div className={`status-circle ${order.status === 'profit' ? 'green' : order.status === 'loss' ? 'red' : 'yellow'}`} />
            <div className="order-info">
              <div>{order.symbol}</div>
              <small>{order.time}</small>
            </div>
            <div className="order-info">
              <div>{order.profit}</div>
              <small>{order.status === 'profit' ? 'Profit (USDT)' : order.status === 'loss' ? 'Loss (USDT)' : 'Error'}</small>
            </div>
            <div className="order-info">
              <div>{order.amount}</div>
              <small>Amount (USDT)</small>
            </div>
            <div className="order-info">
              <div>{order.purchased}</div>
              <small>Purchase (USDT)</small>
            </div>
            <div className="order-info">
              <div>{order.sold}</div>
              <small>Sell (USDT)</small>
            </div>
            <div className="order-info">
              <div>{order.rsi}</div>
              <small>RSI</small>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default HistoryOfOrders;