import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import 'chart.js/auto';
import './graphic.css';

function Graphic({ startTime, endTime, precision }) {
  const { botId } = useParams();
  const token = localStorage.getItem('token');
  const apiUrl = process.env.REACT_APP_API_URL;

  const [profitData, setProfitData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const generateLabels = (start, end) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const labels = [];
  
      while (startDate <= endDate) {
        labels.push(startDate.toLocaleDateString());
        startDate.setDate(startDate.getDate() + precision);
      }
  
      return labels;
    };
  
    const labels = generateLabels(startTime, endTime);
    setLabels(labels);
  
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${apiUrl}/orders/bot/${botId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const orders = response.data;
        const filteredOrders = orders.filter((order) => {
          const orderTime = new Date(order.updatedAt).getTime();
          return orderTime >= startTime && orderTime <= endTime;
        });
  
        const profitArr = Array(labels.length).fill(0);
  
        filteredOrders.forEach((order) => {
          if (order.side === 'SELL' && order.status === 'FILLED') {
            const matchingBuyOrder = orders.find(
              (buyOrder) =>
                buyOrder.side === 'BUY' &&
                buyOrder.symbol === order.symbol &&
                buyOrder.quantity === order.quantity
            );
  
            if (matchingBuyOrder) {
              const profit =
                order.quantity * order.price -
                matchingBuyOrder.quantity * matchingBuyOrder.price;
  
              // Визначаємо індекс періоду
              const dateIndex = Math.floor(
                (new Date(order.updatedAt).getTime() - startTime) /
                (precision * 1000 * 60 * 60 * 24)
              );
  
              if (dateIndex >= 0 && dateIndex < profitArr.length) {
                profitArr[dateIndex] += profit;
              }
            }
          }
        });
  
        setProfitData(profitArr);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
  
    fetchOrders();
  }, [botId, startTime, endTime, precision, apiUrl, token]);  

  const data = {
    labels,
    datasets: [
      {
        label: 'Profit/Loss ($)',
        data: profitData,
        borderColor: '#007bff',
        backgroundColor: 'rgba(0, 123, 255, 0.2)',
        fill: true,
        tension: 0,
        pointRadius: 5,
        pointHoverRadius: 7,
        pointHoverBackgroundColor: '#007bff',
      },
    ],
  };

  const max = Math.max(...profitData) + 3;
  const min = Math.min(...profitData) - 3;
  const stepSize = Math.ceil((max - min) / 10);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          stepSize: stepSize,
        },
        min: min,
        max: max,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const date = new Date(startTime);
            date.setDate(date.getDate() + context.dataIndex);
            return `${date.toLocaleDateString()}: $${context.raw.toFixed(2)}`;
          },
        },
      },
    },
  };

  return (
    <div className="graphic-container">
      <Line data={data} options={options} />
    </div>
  );
}

export default Graphic;
