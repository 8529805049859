import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './add-coin.css';

const AddCoin = ({ onClose, coinsArray, setCoinsArray, apiUrl, botId, token }) => {
  let [formData, setFormData] = useState({
    symbol: '',
    rsi: '',
    profit: '',
    stoploss: '',
  });

  useEffect(() => {
    if (coinsArray.length > 0) {
      const lastCoin = coinsArray[coinsArray.length - 1];
      setFormData({
        symbol: '',
        rsi: lastCoin.rsi,
        profit: lastCoin.profit,
        stoploss: lastCoin.stoploss,
      });
    }
  }, [coinsArray]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedCoinsArray = [...coinsArray, formData];
    
    const coins = {};
    updatedCoinsArray.forEach((coin) => {
      coins[coin.symbol.toUpperCase()] = {
        rsi: parseInt(coin.rsi),
        profit: parseFloat(coin.profit),
        stoploss: parseFloat(coin.stoploss),
      };
    });

    try {
      await axios.put(
        `${apiUrl}/bots/${botId}`,
        {
          coins
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCoinsArray(updatedCoinsArray);
    } catch (error) {
      if (error.response) {
        console.error('Server Error:', error.response.data);
        alert(error.response.data.message || 'An error occurred while adding a coin');
      } else if (error.request) {
        console.error('No response received:', error.request);
        alert('No response from server');
      } else {
        console.error('Error:', error.message);
        alert('An error occurred while adding a coin');
      }
    }
    onClose();
  };

  return (
    <div className="add-coin-overlay">
      <div className="add-coin-popup">
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="symbol">Coin Symbol:</label>
            <input
              type="text"
              name="symbol"
              id="symbol"
              placeholder="Type a Symbol:"
              value={formData.symbol}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="rsi">RSI:</label>
            <input
              type="number"
              name="rsi"
              id="rsi"
              value={formData.rsi}
              onChange={handleChange}
              required
              min="0"
              max="100"
              placeholder="Type a RSI:"
            />
          </div>
          <div className="input-group">
            <label htmlFor="profit">Profit (%):</label>
            <input
              type="number"
              name="profit"
              id="profit"
              value={formData.profit}
              onChange={handleChange}
              required
              placeholder="Type a Profit (%):"
            />
          </div>
          <div className="input-group">
            <label htmlFor="stoploss">Stop Loss (%):</label>
            <input
              type="number"
              name="stoploss"
              id="stoploss"
              value={formData.stoploss}
              onChange={handleChange}
              required
              min="0"
              placeholder="Type a Stop Loss (%):"
            />
          </div>
          <div className="add-coin-actions">
            <button type="button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit">Add</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCoin;
