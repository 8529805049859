import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './statistics.css';
import HistoryOfOrders from '../history-of-orders/history-of-orders';
import Graphic from '../graphic/graphic';

function Statistics() {
  const { botId } = useParams();
  const token = localStorage.getItem('token');
  const apiUrl = process.env.REACT_APP_API_URL;

  const [bot, setBot] = useState(null);
  const [period, setPeriod] = useState(() => localStorage.getItem('period') || '30 days');
  const [profitValue, setProfitValue] = useState(0);
  const [profitPercent, setProfitPercent] = useState(0);
  const [profitableOrders, setProfitableOrders] = useState(0);
  const [lossOrders, setLossOrders] = useState(0);
  const [showHistory, setShowHistory] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(new Date().getTime());
  const [error, setError] = useState(null);
  const [precision, setPrecision] = useState(() => localStorage.getItem('precision') || '1 day');
  const [precisionValue, setPrecisionValue] = useState(1);
  const [showGraphic, setShowGraphic] = useState(true);

  const options = {
    periods: {
      '7 days': 7,
      '30 days': 30,
      '60 days': 60,
      '90 days': 90,
      '1 year': 365, 
    },
    precisions: {
      '1 day': 1,
      '7 days': 7,
      '30 days': 30,
      '90 days': 90,
      '1 year': 365,
    },
  };

  const selectedPeriod = options.periods[period];
  const selectedPrecision = options.precisions[precision];

  const getAvailablePrecisions = (period) => {
    const availablePrecisions = {
      '7 days': ['1 day'],
      '30 days': ['1 day', '7 days'],
      '60 days': ['1 day', '7 days', '30 days'],
      '90 days': ['1 day', '7 days', '30 days'],
      '1 year': ['1 day', '7 days', '30 days', '90 days', '1 year'],
    };
    return availablePrecisions[period] || [];
  };

  const availablePrecisions = getAvailablePrecisions(period);

  useEffect(() => {
    const now = new Date().getTime();
    setStartTime(now - selectedPeriod * 24 * 60 * 60 * 1000);
    setEndTime(now);
  }, [selectedPeriod]);

  useEffect(() => {
    setPrecisionValue(selectedPrecision);
  }, [selectedPrecision]);

  useEffect(() => {
    const fetchBotDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/bots/${botId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBot(response.data);
      } catch (error) {
        console.error('Error fetching bot details:', error);
        setError('Unable to fetch bot details. Please try again later.');
      }
    };

    fetchBotDetails();
  }, [botId, apiUrl, token]);

  useEffect(() => {
    if (!bot) return;

    const fetchOrders = async () => {
      setError(null);

      try {
        const response = await axios.get(`${apiUrl}/orders/bot/${botId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const orders = response.data.filter((order) => {
          if (!startTime) return true;
          const orderTime = new Date(order.updatedAt).getTime();
          return orderTime >= startTime && orderTime <= endTime;
        });

        if (orders.length === 0) {
          setProfitValue(0);
          setProfitableOrders(0);
          setLossOrders(0);
          setProfitPercent(0);
          return;
        }

        const buyOrders = orders.filter(
          (order) => order.side === 'BUY' && order.status === 'FILLED'
        );

        let totalProfit = 0;
        let profitable = 0;
        let loss = 0;

        buyOrders.forEach((orderBought) => {
          const orderSell = orders.find(
            (order) =>
              order.side === 'SELL' &&
              (order.status === 'FILLED' || order.status === 'CANCELED') &&
              order.type === 'LIMIT_MAKER' &&
              order.symbol === orderBought.symbol &&
              order.quantity === orderBought.quantity
          );

          const orderStop = orders.find(
            (order) =>
              order.side === 'SELL' &&
              (order.status === 'FILLED' || order.status === 'CANCELED') &&
              order.type === 'STOP_LOSS_LIMIT' &&
              order.symbol === orderBought.symbol &&
              order.quantity === orderBought.quantity
          );

          let orderSold = null;
          if (orderSell && orderSell.status === 'FILLED') {
            orderSold = orderSell;
          } else if (orderStop && orderStop.status === 'FILLED') {
            orderSold = orderStop;
          }

          if (orderSold) {
            const profit =
              orderSold.price * orderSold.quantity -
              orderBought.price * orderBought.quantity;

            totalProfit += profit;

            if (profit > 0) {
              profitable += 1;
            } else {
              loss += 1;
            }
          }
        });

        setProfitValue(totalProfit);
        setProfitableOrders(profitable);
        setLossOrders(loss);
        setProfitPercent((totalProfit / bot.budget) * 100);
      } catch (err) {
        console.error('Error fetching orders:', err);
        setError('Error fetching orders. Please try again later.');
      }
    };

    fetchOrders();
  }, [bot, botId, startTime, endTime, apiUrl, token]);

  const handlePeriodChange = (e) => {
    const selectedPeriod = e.target.value;
    setPeriod(selectedPeriod);
    localStorage.setItem('period', selectedPeriod);
  };

  const handlePrecisionChange = (e) => {
    const selectedPrecision = e.target.value;
    setPrecision(selectedPrecision);
    localStorage.setItem('precision', selectedPrecision);
  };

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="statistics-container">
      <div className="profit-section">
        <div className="profit-section-text">
          <span className="profit-label">Profit ({period}):</span>
          <span>{profitValue.toFixed(2)} $</span>
          <span>{profitPercent.toFixed(2)} %</span>
        </div>
        <div className="dropdown-block">
          <span className="profit-label">Precision of graphic: </span>
          <select
            className="dropdown"
            value={precision}
            onChange={handlePrecisionChange}
          >
            {availablePrecisions.map((p) => (
              <option key={p} value={p}>
                {p}
              </option>
            ))}
          </select>
          <span className="profit-label">Period of graphic: </span>
          <select
            className="dropdown"
            value={period}
            onChange={handlePeriodChange}
          >
            {Object.keys(options.periods).map((p) => (
              <option key={p} value={p}>
                {p}
              </option>
            ))}
          </select>
          <button
            className="details-toggle-button"
            onClick={() => setShowGraphic((prev) => !prev)}
          >
            {showGraphic ? 'Hide Graphic' : 'Show Graphic'}
          </button>
        </div>
      </div>

      {showGraphic && (
        <div className="graphic-section">
          <Graphic startTime={startTime} endTime={endTime} precision={precisionValue} />
        </div>
      )}

      <div className="orders-history-section">
      <span className="orders-history-label">Orders history</span>
        <div className="orders-history-block">
          <span className="orders-summary profit">Profitable: {profitableOrders}</span>
          <span className="orders-summary loss">Loss: {lossOrders}</span>
          <span className="orders-summary">Total: {profitableOrders + lossOrders}</span>          
          <button
            className="details-toggle-button"
            onClick={() => setShowHistory((prev) => !prev)}
          >
            {showHistory ? 'Hide' : 'Show'}
          </button>
        </div>
      </div>

      {showHistory && (
        <div className={`history-module ${!showHistory ? 'hidden' : ''}`}>
            <HistoryOfOrders startPeriod={startTime} endPeriod={endTime} />
        </div>
      )}
    </div>
  );
}

export default Statistics;