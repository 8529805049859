import React from 'react';
import axios from 'axios';
import { FaPlus } from 'react-icons/fa';
import './coin-info.css';

function CoinInfo({ coinsArray, setCoinsArray, setPopupOpen, botId, apiUrl, token }) {
    const handleCoinChange = (index, field, value) => {
        setCoinsArray((prevCoins) => {
          const newCoins = [...prevCoins];
          newCoins[index] = {
            ...newCoins[index],
            [field]: value,
          };
          return newCoins;
        });
    };

    const handleRemoveCoin = async (index) => {
        const confirmed = window.confirm('Ви дійсно хочете видалити цю монету?');
        if (!confirmed) return;

        const updatedCoinsArray = [...coinsArray];
        updatedCoinsArray.splice(index, 1);

        const coins = {};
        updatedCoinsArray.forEach((coin) => {
            coins[coin.symbol.toUpperCase()] = {
            rsi: parseInt(coin.rsi),
            profit: parseFloat(coin.profit),
            stoploss: parseFloat(coin.stoploss),
            };
        });

        try {
            const response = await axios.put(
                `${apiUrl}/bots/${botId}`,
                {
                    coins
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            alert(response.data.message || 'Coin successfully removed');
            setCoinsArray(updatedCoinsArray);

        } catch (error) {
            if (error.response) {
                console.error('Server Error:', error.response.data);
                alert(error.response.data.message || 'An error occurred while removing a coin');
            } else if (error.request) {
                console.error('No response received:', error.request);
                alert('No response from server');
            } else {
                console.error('Error:', error.message);
                alert('An error occurred while removing a coin');
            }
        }
    };       

  return (
    <div className="coin-info-wrapper">
    {coinsArray.map((coin, index) => (
        <div key={index} className="coin-info-container">
            <div className="coin-info-header">
                <div className="header-text">{coin.symbol}</div>
                <div 
                    className="remove-icon"
                    onClick={() => handleRemoveCoin(index)}
                >✖</div>
            </div>
            <div className="coin-info-field">
                <div className="coin-info-label">RSI:</div>
                <input
                type="number"
                value={coin.rsi}
                onChange={(e) => handleCoinChange(index, 'rsi', e.target.value)}
                className="coin-info-input"
                />
            </div>
            <div className="coin-info-field">
                <div className="coin-info-label">PROFIT:</div>
                <input
                type="number"
                value={coin.profit}
                onChange={(e) => handleCoinChange(index, 'profit', e.target.value)}
                className="coin-info-input"
                />
            </div>
            <div className="coin-info-field">
                <div className="coin-info-label">STOP:</div>
                <input
                type="number"
                value={coin.stoploss}
                onChange={(e) => handleCoinChange(index, 'stoploss', e.target.value)}
                className="coin-info-input"
                />
            </div>
        </div>
    ))}
    <div className="coin-info-container plus" onClick={() => setPopupOpen(true)}>
        <FaPlus/>
    </div>
    </div>
  );
}

export default CoinInfo;