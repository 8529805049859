import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './active-orders.css';

function ActiveOrders() {
  const { botId } = useParams();
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${apiUrl}/orders/bot/${botId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;

        const activeOrders = data.filter(order => order.side === 'BUY' && order.status === 'NEW');
        const formattedActiveOrders = activeOrders.map(order => ({
          id: order.id,
          status: '',
          symbol: order.symbol,
          amount: order.amount,
          purchasePrice: order.price,
          sellPrice: '–',
          stopPrice: '–',
          rsi: order.rsi,
          time: 'not bought',
        }));

        const completedOrders = data.filter(order => order.side === 'BUY' && order.status === 'FILLED')
          .map(orderBuy => {
            const orderSell = data.find(
              order => order.side === 'SELL' &&
                order.status === 'NEW' &&
                order.type === 'LIMIT_MAKER' &&
                order.symbol === orderBuy.symbol &&
                order.quantity === orderBuy.quantity
            );

            const orderStop = data.find(
              order => order.side === 'SELL' &&
                order.status === 'NEW' &&
                order.type === 'STOP_LOSS_LIMIT' &&
                order.symbol === orderBuy.symbol &&
                order.quantity === orderBuy.quantity
            );

            if (orderSell && orderStop) {
              return {
                id: orderBuy.id,
                status: 'completed',
                symbol: orderBuy.symbol,
                amount: orderBuy.amount,
                purchasePrice: orderBuy.price,
                sellPrice: orderSell.price,
                stopPrice: orderStop.price,
                rsi: orderBuy.rsi,
                time: new Date(orderBuy.updatedAt).toLocaleString('en-GB', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                }),
              };
            }

            return null;
          })
          .filter(order => order !== null);

        const allOrders = [...completedOrders, ...formattedActiveOrders];
        allOrders.sort((a, b) => parseFloat(a.purchasePrice) - parseFloat(b.purchasePrice)); // сортування за ціною покупки

        setOrders(allOrders);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError(error.message || 'Something went wrong');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [botId, token, apiUrl]);

  if (isLoading) {
    return <div className="loading">Loading active orders...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  const handleDeleteOrder = async (orderId) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this order?');
    if (isConfirmed) {
      try {
        await axios.delete(`${apiUrl}/orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId));
      } catch (error) {
        alert('Error deleting order: ' + error.message);
        console.error('Error deleting order:', error);
      }
    }
  };

  return (
    <div className="active-orders-container">
      {orders.length === 0 ? (
        <div className="no-orders">No active orders found.</div>
      ) : (
        orders.map(order => (
          <div key={order.id} className="order">
            <div className={`status-circle ${order.status === 'completed' ? 'green' : 'gray'}`} />
            <div className="order-info">
              <div>{order.symbol}</div>
              <small>{order.time}</small>
            </div>
            <div className="order-info">
              <div>{order.amount}</div>
              <small>Amount (USDT)</small>
            </div>
            <div className="order-info">
              <div>{order.purchasePrice}</div>
              <small>Purchase (USDT)</small>
            </div>
            <div className="order-info">
              <div>{order.sellPrice}</div>
              <small>Sell (USDT)</small>
            </div>
            <div className="order-info">
              <div>{order.stopPrice}</div>
              <small>Stop (USDT)</small>
            </div>
            <div className="order-info">
              <div>{order.rsi}</div>
              <small>RSI</small>
            </div>
            {order.status === '' && (
              <button className="cancel-order-button" onClick={() => handleDeleteOrder(order.id)}>×</button>
            )}
          </div>
        ))
      )}
    </div>
  );
}

export default ActiveOrders;
