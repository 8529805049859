import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './bot-edit.css';
import CoinInfo from '../coin-info/coin-info';
import AddCoin from '../add-coin/add-coin';
import { FaEdit } from 'react-icons/fa';

const apiUrl = process.env.REACT_APP_API_URL;

function BotEdit() {
  const { botId } = useParams();
  const [bot, setBot] = useState({
    name: '',
    type: '',
    budget: '',
    coins: {},
    settings: {
      bot_limit: '',
      order_value: 0,
      interval: '15m',
    },
    status: 0,
  });
  const [coinsArray, setCoinsArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPopupOpen, setPopupOpen] = useState(false);

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchBotDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/bots/${botId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const botData = response.data;
        const coinsData = botData.coins || {};
        const coinsArray = Object.keys(coinsData).map((coinSymbol) => ({
          symbol: coinSymbol,
          rsi: coinsData[coinSymbol].rsi,
          profit: coinsData[coinSymbol].profit,
          stoploss: coinsData[coinSymbol].stoploss,
        }));
        setCoinsArray(coinsArray);
        setBot(botData);
      } catch (error) {
        console.error('Error fetching bot details:', error);
      }
    };

    fetchBotDetails();
  }, [botId, token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBot((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBudgetChange = (e) => {
    const budget = e.target.value;
    setBot((prevState) => {
      const botLimit = prevState.settings.bot_limit || 1;
      const orderValue = budget / botLimit;
      return {
        ...prevState,
        budget: budget,
        settings: {
          ...prevState.settings,
          order_value: orderValue,
        },
      };
    });
  };

  const handleBotLimitChange = (e) => {
    const botLimit = e.target.value;
    setBot((prevState) => {
      const budget = prevState.budget || 0;
      const orderValue = budget / botLimit;
      return {
        ...prevState,
        settings: {
          ...prevState.settings,
          bot_limit: botLimit,
          order_value: orderValue,
        },
      };
    });
  };

  const handleIntervalChange = (e) => {
    const interval = e.target.value;
    setBot((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        interval,
      },
    }));
  };

  const handleAllProfitChange = (e) => {
    const profitValue = e.target.value;
      setCoinsArray((prevCoins) =>
        prevCoins.map((coin) => ({ ...coin, profit: profitValue }))
      );
  };
  
  const handleAllStopLossChange = (e) => {
    const stopLossValue = e.target.value;
      setCoinsArray((prevCoins) =>
        prevCoins.map((coin) => ({ ...coin, stoploss: stopLossValue }))
      );
  };  

  const handleAllRsiChange = (e) => {
    const rsiValue = e.target.value;
      setCoinsArray((prevCoins) =>
        prevCoins.map((coin) => ({ ...coin, rsi: rsiValue }))
      );
  };

  const handleSave = async () => {
    setErrorMessage('');
    if (!bot.name || !bot.budget || !bot.settings.bot_limit || coinsArray.length === 0) {
      setErrorMessage('Усі поля обов\'язкові для заповнення.');
      return;
    }
    for (const coin of coinsArray) {
      if (!coin.symbol || !coin.rsi || !coin.profit || !coin.stoploss) {
        setErrorMessage('Усі поля для кожної монети повинні бути заповнені.');
        return;
      }
    }

    try {
      const coins = {};
      coinsArray.forEach((coin) => {
        coins[coin.symbol.toUpperCase()] = {
          rsi: parseInt(coin.rsi),
          profit: parseFloat(coin.profit),
          stoploss: parseFloat(coin.stoploss),
        };
      });

      const orderValue = bot.budget / bot.settings.bot_limit;

      const updatedBot = {
        ...bot,
        coins,
        settings: {
          ...bot.settings,
          order_value: orderValue,
        },
      };

      await axios.put(
        `${apiUrl}/bots/${botId}`,
        updatedBot,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert('Data successfully updated');
    } catch (error) {
      console.error('Error updating bot details:', error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message || 'Сталася помилка');
      } else {
        setErrorMessage('Сталася помилка при оновленні бота');
      }
    }
  };

  const [isEditable, setIsEditable] = useState({
    name: false,
    budget: false,
  });

  const toggleEdit = (field) => {
    setIsEditable((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleStatusChange = async (newStatus) => {
    if (bot.status === newStatus) return;
  
    try {
      const updatedBot = { ...bot, status: newStatus };
  
      await axios.put(`${apiUrl}/bots/${botId}`, updatedBot, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      setBot((prevBot) => ({
        ...prevBot,
        status: newStatus,
      }));
    } catch (error) {
      console.error('Error updating status:', error);
      setErrorMessage('Сталася помилка при зміні статусу.');
    }
  };  

  return (
    <div>
      {isPopupOpen && (
        <AddCoin onClose={() => setPopupOpen(false)} coinsArray={coinsArray} setCoinsArray={setCoinsArray} apiUrl={apiUrl} botId={botId} token={token}/>
      )}
      <div className="bot-edit-container">
        <div className="bot-edit-header">
          <div className="form-group">
              <input
                id="bot-name"
                type="text"
                name="name"
                value={bot.name}
                onChange={handleInputChange}
                className="form-input"
                disabled={!isEditable.name}
              />
            
            <div>
              <small className="form-hint">name</small>
              <FaEdit
                  className="edit-icon"
                  onClick={() => toggleEdit('name')}
                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                />
            </div>
          </div>

          <div className="form-group">
            <div className="form-block">
              <input
                id="bot-type"
                type="text"
                name="type"
                value={bot.type}
                onChange={handleInputChange}
                className="form-input"
                disabled="false"
              />
            </div>
            <small className="form-hint">type</small>
          </div>

          <div className="form-group">
              <input
                id="bot-budget"
                type="number"
                name="budget"
                value={bot.budget}
                onChange={handleBudgetChange}
                className="form-input"
                disabled={!isEditable.budget}
              />
            <div>
              <small className="form-hint">budget (USDT)</small>
              <FaEdit
                  className="edit-icon"
                  onClick={(event) => toggleEdit('budget')}
                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                />
            </div>
          </div>

          <div className="form-group">
          <div className="form-block">
              <input
                id="bot-value"
                type="number"
                value={bot.settings.order_value}
                readOnly
                className="form-input"
                disabled="false"
              />
            </div>
            <small className="form-hint">budget per bot</small>
          </div>

          <div className="form-group">
            <div className="form-block">
              <select
                id="bot-interval"
                name="interval"
                value={bot.settings.interval}
                onChange={handleIntervalChange}
                className="form-select"
              >
                <option value="15m">15m</option>
                <option value="1h">1h</option>
                <option value="4h">4h</option>
                <option value="1d">1d</option>
              </select>
            </div>
            <small className="form-hint">interval</small>
          </div>
        </div>
        <div className="form-group changes-group">
          <div className="status-group">
            <span>Bot Status: </span>
            <button
              className={`status-btn ${bot.status === 1 ? 'active' : ''}`}
              onClick={() => handleStatusChange(1)}
            >
              Active
            </button>
            <button
              className={`status-btn ${bot.status === 0 ? 'stopped' : ''}`}
              onClick={() => handleStatusChange(0)}
            >
              Stopped
            </button>
          </div>
          <button
            className={`status-btn-save`}
            onClick={() => handleSave()}
          >
            Save & Apply All Changes
          </button>
        </div>
        <div className="form-group mass-edit-container">
          <div>
            <input
              id="mass-rsi"
              type="number"
              onChange={handleBotLimitChange}
              value={bot.settings.bot_limit}
              className="general-settings-input"
            />
            <small className="form-hint form-hint-general-settings">Limit of orders running together</small>
          </div>
          <div>
            <input
              id="mass-rsi"
              type="number"
              onChange={handleAllRsiChange}
              placeholder="RSI value"
              className="general-settings-input"
            />
            <small className="form-hint form-hint-general-settings">Set one RSI value for all pairs</small>
          </div>
          <div>
            <input
              id="mass-profit"
              type="number"
              onChange={handleAllProfitChange}
              placeholder="Profit value (%)"
              className="general-settings-input"
            />
            <small className="form-hint form-hint-general-settings">Set one Profit value for all pairs</small>
          </div>
          <div>
            <input
              id="mass-stoploss"
              type="number"
              onChange={handleAllStopLossChange}
              placeholder="Stop-loss value (%)"
              className="general-settings-input"
            />
            <small className="form-hint form-hint-general-settings">Set one Stop-loss value for all pairs</small>
          </div>
        </div>
        <div className='advanced-edit-container'>
          <CoinInfo coinsArray={coinsArray} setCoinsArray={setCoinsArray} setPopupOpen={setPopupOpen} botId={botId} apiUrl={apiUrl} token={token}/>
        </div>
      </div>
    </div>
  );
}

export default BotEdit;