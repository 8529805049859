import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './bot-detail.css';
import ActiveOrders from '../active-orders/active-orders';
import HistoryOfOrders from '../history-of-orders/history-of-orders';
import Statistics from '../statistics/statistics';

function BotDetail() {
  const { botId } = useParams();
  const [bot, setBot] = useState(null);
  const [activeTab, setActiveTab] = useState('Statistics');
  const token = localStorage.getItem('token');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBotDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/bots/${botId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBot(response.data);
      } catch (error) {
        console.error('Error fetching bot details:', error);
      }
    };

    fetchBotDetails();
  }, [botId, token, apiUrl]);

  const handleStatusToggle = async () => {
    const newStatus = bot.status === 1 ? 0 : 1;

    try {
      await axios.put(
        `${apiUrl}/bots/${botId}`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBot((prevState) => ({ ...prevState, status: newStatus }));
    } catch (error) {
      console.error('Error updating bot status:', error);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Statistics':
        return (
          <div>
            <Statistics />
          </div>
        )
      case 'Active orders':
        return (
          <div>
            <ActiveOrders />
          </div>
        );
      case 'History of orders':
        return (
          <div className="HistoryOfOrders">
            <HistoryOfOrders />
          </div>
        );
      default:
        return null;
    }
  };

  if (!bot) {
    return <div>Завантаження...</div>;
  }

  return (
    <div className="container">
      <div className="bot-detail-header">
        <div>
          {bot.name}
          <div>
            <small className="form-hint">name</small>
          </div>
        </div>

        <div>
          {bot.type}
          <div>
            <small className="form-hint">type</small>
          </div>
        </div>

        <div>
          {bot.budget}
          <div>
            <small className="form-hint">budget (USDT)</small>
          </div>
        </div>

        <div>
          {bot.settings.order_value}
          <div>
            <small className="form-hint">budget per bot</small>
          </div>
        </div>

        <div>
          {bot.settings.interval}
          <div>
            <small className="form-hint">interval</small>
          </div>
        </div>

        <div>
          <div className="status-toggle" onClick={handleStatusToggle}>
            <span>{bot.status === 0 ? 'Off' : 'On'}</span>
            <label className="switch">
              <input type="checkbox" checked={bot.status === 1} readOnly />
              <span className="slider round"></span>
            </label>
          </div>
          <small className="form-hint">status</small>
        </div>
      </div>

      {/* Новий блок меню */}
      <div className="menu">
        {['Statistics', 'Active orders', 'History of orders'].map((tab) => (
          <button
            key={tab}
            className={`menu-button ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Вміст активної вкладки */}
      <div className="tab-content">{renderTabContent()}</div>
    </div>
  );
}

export default BotDetail;
